import React, {useEffect, useState} from 'react';
import {LinkContainer} from 'react-router-bootstrap';
import {Table, Button, Row, Col, Container, Form} from 'react-bootstrap';
import Pagination from "@mui/lab/Pagination";
import Message from '../components/Message';
import Loader from '../components/Loader';
import axios from '../utils/axios';

const TeachQuestionListView = ({history, match}) => {

  const [name, setName] = useState ('');
  const [qList, setQList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [total, setTotal] = useState(0);

  const createQuestionHandler = async () => {
    await axios.post (`/teacher/createQuestion`, {
      language: window.localStorage.getItem ('lang') || 'en',
    });
    getQuestionList();
  };

  const deleteQuestionHandler = async (id) => {
    if (window.confirm ('Are you sure')) {
      await axios.post (`/teacher/deleteQuestion`, {id});
      getQuestionList();
    }
  };

  const searchQuestion = () => {
    if (page === 1) {
      getQuestionList();
    } else {
      setPage(1);
    }
  }

  const getQuestionList = async () => {
    setLoading(true);
    try {
      const res = await axios.post (
        `/teacher/getQuestionsByTeacher`,
        { 
          name,
          language: window.localStorage.getItem ('lang') || 'en',
          page,
          pageSize,
        },
      );
      const { list, total } = res.result;
      setQList(list);
      setTotal(total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const uploadFile = async e => {
    const file = e.target.files[0];
    // 判断是否是 excel
    if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      alert('Please upload excel file!');
      return;
    }
    const formData = new FormData ();
    formData.append ('file', file);
    formData.append ('type', 2);

    const res = await axios.post('/file/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const { path } = res.result;

    await axios.post('/teacher/batchCreateQuestion', {
      filePath: path,
      language: window.localStorage.getItem ('lang') || 'en',
    });

    if (page === 1) {
      getQuestionList();
    } else {
      setPage(1);
    }
    document.getElementById('question-upload-file').value = '';
  }

  const copy = (id) => {
    axios.post('/teacher/copyQuestion', {
      id
    }).then(res => {
      getQuestionList();
    })
  }

  const changePage = (event, value) => {
    setPage(value);
  }

  useEffect(() => {
    getQuestionList();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Row className="align-items-center">
        <Col>
          <h1>My Questions </h1>
        </Col>
        <Col className="text-right">
          <Button className="my-3" size="sm" onClick={createQuestionHandler} style={{ marginRight: '6px' }}>
            <i className="fas fa-plus" /> Create
          </Button>
          <Button className="my-3" size="sm" style={{ position: 'relative', marginRight: '6px' }}>
            <i className="fas fa-plus" /> Batch Create
            <input
              type='file'
              id="question-upload-file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              style={{ opacity: 0, position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}
              onChange={uploadFile} />
          </Button>
          <Button variant="primary" size="sm" href="/api/question_template.xlsx">Download Template</Button>
        </Col>
      </Row>
      <Container>
        <Form inline style={{ marginBottom: '10px' }}>
          <Form.Group controlId="search">
            <Form.Control
              className="mr-sm-3 ml-sm-6"
              type="text"
              name="name"
              value={name}
              onChange={e => setName (e.target.value)}
              placeholder="Search By NAME"
            />
            <Button onClick={searchQuestion} variant="outline-success">
              Search
            </Button>
          </Form.Group>
        </Form>
        {
          loading ? <Loader /> :
          qList.length > 0 ? (
            <Table striped bordered hover responsive className="table-sm">
              <thead>
                <tr>

                  <th>Name</th>
                  <th>id</th>
                  <th>Category</th>
                  <th>Difficuty</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {qList.map (q => (
                  <tr key={q._id}>
                    <td>{q.name}</td>
                    <td>{q._id}</td>
                    <td>{q.category}</td>
                    <td>{q.difficuty}</td>

                    <td>
                      <LinkContainer to={`/teach/question/${q._id}/edit`}>
                        <Button variant="light" className="btn-sm">
                          <i className="fas fa-edit" />
                        </Button>
                      </LinkContainer>
                      <Button variant="light" className="btn-sm" onClick={() => copy(q._id)}>
                        <i className="fas fa-copy" />
                      </Button>
                      <Button
                        variant="danger"
                        className="btn-sm"
                        onClick={() => deleteQuestionHandler (q._id)}
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    </td>

                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Message variant="info">
              No question found, change language or search again
            </Message>
          )
        }
        
      </Container>
      <div style={{ marginTop: '30px' }}>
        <Pagination
            count={Math.ceil(total / pageSize)}
            page={page}
            onChange={changePage}
        />
      </div>
    </Container>
  );
};

export default TeachQuestionListView;
