import React, { useEffect, useState, useCallback } from 'react';
import { Table, Button, Row, Col, Container, Badge } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import axios from '../utils/axios';
import { getCorrectNumber } from '../utils/correct';
import RichText from '../components/RichText';

const MyHomeworkView = ({ history, match }) => {
    const id = match.params.id;
    const [homework, setHomework] = useState([]);
    const [bulletin, setBulletin] = useState('');

    const getHomework = () => {
        axios.get(`/user/getExerciseByClassId?id=${id}`).then(res => {
            setHomework(res.result.list);
        });
    }

    const getClass = () => {
        axios.get('/public/getClassById?id=' + id).then(res => {
            setBulletin(res.result.bulletin);
        });
    }

    const undoExercise = (id) => {
        if (window.confirm('Are you sure you want to undo this homework?')) {
            axios.post('/user/undoExercise', { id }).then(res => {
                getHomework();
            });
        }
    }

    const startLearning = (homework) => {
        // history.push(`/student/exerciseEdit/${homework._id}`);
        const { homeworkId, endExamAt } = homework;
        const { isExam } = homeworkId;
        if (isExam) {
            if (endExamAt) {
                if (new Date(endExamAt) > new Date()) {
                    history.push(`/student/exerciseEdit/${homework._id}`);
                } else {
                    alert('The exam has ended.');
                }
            } else {
                // const res = window.confirm('The exam has not started yet. Do you want to start now?');
                // if (res) {
                //     axios.post('/user/startExam', { id: homework._id }).then(res => {
                //         history.push(`/student/exerciseEdit/${homework._id}`);
                //     });
                // }
                history.push(`/student/exerciseEdit/${homework._id}`);
            }
        } else {
            history.push(`/student/exerciseEdit/${homework._id}`);
        }
    }

    useEffect(() => {
        getHomework();
        getClass();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container>
            <Row className="align-items-center">
                <Col>
                    <h1>Homework </h1>
                </Col>
            </Row>
            <Container>
                {
                    bulletin && 
                    // <div className='class-bulletin rich-text' dangerouslySetInnerHTML={{ __html: bulletin }}></div>
                    <RichText className='class-bulletin' content={bulletin} />
                }
                <Table striped bordered hover responsive className="table-sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Due Date</th>
                            <th>Progress Tracker</th>
                            <th>Accuracy Tracker</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {homework.map(homework => (
                            <tr key={homework._id}>
                                <td>{homework.homeworkId.name}</td>
                                <td>
                                    {homework.homeworkId.dueDate ? homework.homeworkId.dueDate.split('T')[0] : ''}&nbsp;
                                    {
                                        (new Date(homework.homeworkId.dueDate) < new Date() && homework.questionList.some(q => !q.answeredAt)
                                        )
                                        && <Badge variant="danger">overdue</Badge>
                                    }
                                </td>
                                <td>
                                    {homework.questionList.filter(q => q.answeredAt).length} / {homework.questionList.length}
                                </td>
                                <td>
                                    {getCorrectNumber(homework)} / {homework.questionList.length}
                                </td>
                                <td>
                                    {
                                        homework.questionList.filter(q => q.answeredAt).length === homework.questionList.length ?
                                        (
                                            <LinkContainer to={`/student/exercise/${homework._id}`}>
                                                <Button variant="info" className="btn-sm mr-3">View details</Button>
                                            </LinkContainer>
                                        ) : (
                                            // <LinkContainer to={`/student/exerciseEdit/${homework._id}`}>
                                                <Button variant="info" className="btn-sm mr-3" onClick={() => startLearning(homework)}>start learning</Button>
                                            // </LinkContainer>
                                        )
                                    }
                                    {
                                        !homework.homeworkId.isExam &&
                                        <Button variant="danger" className="btn-sm" onClick={() => undoExercise(homework._id)}>Undo</Button>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </Container>
    );
};

export default MyHomeworkView;
